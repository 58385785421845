import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vSelect from "vue-select";
import axios from 'axios';

Vue.config.productionTip = false;

Vue.component("v-select", vSelect);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
